// -------------------------------------
// SPACING Sass Variables
// -------------------------------------

$vertical-rhythm-unit: 11px;

// spacing - horizontal
$xsmall-horizontal-spacing: 5px;
$small-horizontal-spacing: 10px;
$medium-horizontal-spacing: 15px;
$large-horizontal-spacing: 20px;
$xlarge-horizontal-spacing: 25px;
$xxlarge-horizontal-spacing: 30px;
$xxxlarge-horizontal-spacing: 40px;

// spacing - vertical
$xsmall-vertical-spacing: 6px;
$small-vertical-spacing: $vertical-rhythm-unit;
$medium-vertical-spacing: 2 * $vertical-rhythm-unit;
$large-vertical-spacing: 3 * $vertical-rhythm-unit;
$xlarge-vertical-spacing: 4 * $vertical-rhythm-unit;
$xxlarge-vertical-spacing: 5 * $vertical-rhythm-unit;
$xxxlarge-vertical-spacing: 10 * $vertical-rhythm-unit;
