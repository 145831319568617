// grayscale
$xdarkBlack: #142135;
$darkBlack: #1f2c40;
$black: #26374d;
$xdarkGray: #455774;
$darkGray: #7c8eae;
$gray: #96a8c7;
$subtleGray: #b3c8e5;
$lightGray: #d4e2f8;
$xlightGray: #eff5ff;
$white: #fff;

$midnightBlue: #0a1c39;
$deepBlue: #0d4878;
$darkBlue: #176ca6;
$blue: #298fce;
$mediumBlue: #2db4e8;
$lightBlue: rgba($mediumBlue, 0.12);
$electricBlue: #39d9f9;
$darkGreen: #004b46;
$green: #128977;
$mediumGreen: #34e2c8;
$lightGreen: rgba($mediumGreen, 0.12);
$darkRed: #980e3f;
$red: #c43f5d;
$mediumRed: #ff839f;
$lightRed: rgba($mediumRed, 0.12);
$yellow: #ffb900;
$mustard: #d68412;
$purple: #ad84d3;
$lightPurple: rgba($purple, 0.12);
$orange: #ff6b0E;

$controlBorderGray: #bcbdbe;
