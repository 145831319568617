@import "~semantic-ui-css/semantic.css";

/*-------------------------------------
    Sass Base
-------------------------------------*/
@import "lib/mixins";
@import "lib/color-variables";
@import "lib/typography-variables";
@import "lib/spacing-variables";
@import "lib/border-variables";

@import "pages/sidebar";
@import "objects/table";


body, h1, h2, h3, h4, h5, button {
  font-family: 'Roboto', sans-serif;
}

body {
  // background-image: url("../images/funky-lines.png");
  background-color: #f7f7f7;
}

a {
  color: #126B87;
}

a:hover {
  color: #31acd4;
}

.jumbo {
  margin-top: 10rem;
  text-align: center;
  color: black;
}

.navbar {
  background: #f2f2f2;
}

.mslogin {
  width: 20rem;
  margin-top: 20rem;
}

.gdc-button {
  margin: 0px 10px;
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  background-color: #5298F2;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.gdc-button:focus {
  outline: none;
}

.button-selected:hover {
  transform: scale(1.05);
}

.button-unselected {
  background-color: #a6a6a6;
}

.button-unselected:hover {
  transform: scale(1.05);
  background-color: #5298F2;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.content-container {
  margin: 20px 20px 40px 300px;
  padding-left: 0px;
  max-width: 1500px;
  position: relative;
}

.full-page-content-container {
  margin: 20px 20px 40px 20px;
  padding-left: 0px;
  max-width: 1500px;
  position: relative;
}

.page-title {
  color: #126b87;
  margin-bottom: 5px;
}

.page-subheader {
  color: #126b87;
  margin: 0px 0px 10px 0px;
  font-style: italic;
}

.page-section-container {
  margin: 20px 0px 0px 0px;
  background-color: #ececec;
  border-radius: 10px;
  padding: 10px 10px 15px;
  width: 600px;
  max-width: 50vw;
}

.page-section-container td, .page-section-container li {
  font-size: 12px;
}

.page-section-container ul {
  padding-left: 2px;
}

.page-section-container li {
  list-style-type: none;
}

.page-section-header {
  margin: 0px 0px 10px 0px;
}

.page-section-data {
  font-size: 14px;
  line-height: 1.2rem;
  margin: 5px 0px;
}

.notes {
  font-size: 14px;
  font-style: italic;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.summary-description {
  font-size: 12px;
  font-style: italic;
}

.page-status-container {
  background-color: #f36c1a;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  width: 150px;
  padding: 5px;
}

.page-status-text {
  font-size: 16px;
  line-height: 1.2rem;
  margin: 0px 0px 5px 0px;
}

.form-page-container {
  padding: 20px 0px 100px 0px;
}

.message-container {
  position: fixed;
  bottom: 20px;
  width: 100%;
}

.form-section-container {
  margin: 20px auto 20px auto;
  background-color: #fff;
  padding: 10px 10px 15px;
  width: 100%;
  border: none;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.inline-container {
  margin: 20px 0px;
}

.inline-container > div {
  display: inline;
  margin: 0px 5px;
}

/*******************************************
  Styling for Veritas Inputs:
*******************************************/

.veritas-input, .veritas-textarea {
  border-radius: 5px;
  border: 1px solid #c4c7ca;
  color: #333333;
  line-height: 1.5;
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px;
  transition: border linear .2s, box-shadow linear .2s;
}

.veritas-input:focus, .veritas-textarea:focus {
  border-color: rgba(49, 172, 212, 0.8);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(49,172,212,.6);
  outline: none;
}

.label-text {
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: normal;
}

.table-label-text {
  vertical-align: top;
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: normal;
}

.label-text-bold {
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: 5px;
}

.form-label {
  font-size: 13px;
  font-weight: bold;
}

.add-input-sidebar .react-toggle {
  margin-right: 8px;
  vertical-align: middle;
}

.form-item-container {
  margin: 10px 0px;
}

.form-equal-width-container {
  width: 50%;
  display: inline-block;
}

/*******************************************
  Styling for React CSS Transitions:
*******************************************/

.backdrop-fade-enter {
  opacity: 0.01;
}

.backdrop-fade-enter-active {
  opacity: 1;
  transition: 300ms ease-in-out;
}

.backdrop-fade-exit {
  opacity: 1;
}

.backdrop-fade-exit-active {
  opacity: 0.01;
  transition: 300ms ease-in-out;
}

.slider-enter {
  opacity: 0.01;
  width: 0px;
}

.slider-enter-active {
  opacity: 1;
  transition: all 300ms ease-out;
  width: 400px;
}

.slider-exit {
  opacity: 1;
  width: 400px;
}

.slider-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-out;
  width: 0px;
}

.consent-section-enter {
  opacity: 0.01;
  transform: translateY(-20%);
}

.consent-section-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 300ms ease-out;
}

.consent-section-exit {
  opacity: 1;
  transform: translateY(0%);
}

.consent-section-exit-active {
  opacity: 0.01;
  transform: translateY(-20%);
  transition: all 300ms ease-out;
}

.table-header {
  text-align: center;
  text-transform: uppercase;
}


.marvel-container {
    width: 100%;
    height: 100%;
}

.gdc-logo {
    object-fit: cover;
    display: inline-block;
    width: 100px;
    height: 45px;
    padding: 4px;
}

//This is to remove inherent padding of the semantic ui header menu item
.ui.menu.fixed .item:first-child {
    padding: 0;
}

.login-container {
    margin-top: 50px;
}

.login-form {
    max-width: 60% !important;
    margin: auto;
}

.page-content-container {
    position: absolute;
    margin-left: 280px;
    margin-top: 60px;
    margin-bottom: 50px;
}

.ui.table thead tr:first-child > th {
    position: sticky !important;
    top: 50px;
    z-index: 2;
}
