// Font Stack
$din-fontStack: "DIN 2014", "Whitney SSm A", "Whitney SSm B", sans-serif;
$monospace-fontStack: Monaco, Consolas, "Lucida Console", monospace;
$whitney-fontStack: "Whitney SSm A", "Whitney SSm B", sans-serif;

// Font Size
$small-fontSize: 13px;
$normal-fontSize: 15px;
$large-fontSize: 20px;
$xlarge-fontSize: 24px;
$xxlarge-fontSize: 36px;

// Font Weight
$normal-dinFontWeight: 300;
$normal-fontWeight: 500;
$bold-fontWeight: 600;

// Letter Spacing
$dinDemi-letterSpacing: 0.3px;
