/*=============================================================================
    TABLES - DO NOT MODIFY
    ---------------------------------------------------------------------------
    Default styling and modifiers for tables
=============================================================================*/

/*-------------------------------------
    Base table
-------------------------------------*/
table {
    background-color: white;
    color: $darkGray;
    margin-bottom: $xlarge-vertical-spacing;
    width: 100%;
    border-collapse: collapse;

    th {
        @include smallCaps;
        border-bottom: 3px solid $lightGray;
        color: $darkGray;
        line-height: 1;
        padding: 5px $medium-horizontal-spacing;
        text-align: left;
        vertical-align: middle;
    }

    td {
        border-bottom: 2px solid $lightGray;
        padding: 5px $medium-horizontal-spacing 6px;
        vertical-align: middle;
    }

    td.low-width {
        width: $xxxlarge-horizontal-spacing;
    }

    td p.service-name {
        width: 10 * $xxlarge-horizontal-spacing;
        margin-bottom: 0;
    }

    td p.service-config {
        margin: 0;
        font-style: italic;
    }

    // Handle tables with multiple tbody elements (e.g. analytics V2)
    tbody:last-child tr:last-child td {
        border-bottom: none;
    }
}
