.sidebar {
    .sidebar-container {
    background-color: #e6e6e6;
    position: fixed;
    height: 100vh;
    width: inherit;
    top: 0;
    padding: 70px 20px 20px 20px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14);
    text-align: center;
    overflow: auto;
    }

  .sidebar-container::-webkit-scrollbar {
      display: none;
  }

  .divider-background {
      position: relative;
      margin: 15px 0px;
  }

  .divider-background hr {
      margin-top: 10px;
      margin-bottom: 10px;
      border-top: 1px solid #444;
  }

  .sidebar-title {
      font-size: 18px;
      font-weight: bold;
      color: #444;
      margin: 0px 0px 10px 0px;
      text-transform: uppercase;
  }

  .sidebar-filter-container {
      margin: 5px 0px;
  }

  .sidebar-buttons {
      text-align: left;
  }
}
